import { Loader2 } from 'lucide-react';
import { t } from 'i18next';
import { Button, ButtonProps } from '@/Components/ui/button';
import React from "react";

interface SaveButtonProps {
    isProcessing: boolean
    size: ButtonProps['size']
    text?: string
}

const SaveButton: React.FC<SaveButtonProps> = ({ isProcessing, size, text = t('buttons.save') }) => {
    return (
        <Button size={size} disabled={isProcessing} className="ms-4">
            {isProcessing && (
                <Loader2 className="h-4 w-4 mr-1 animate-spin" />
            )}
            {text}
        </Button>
    );
};

export default SaveButton;
